/* Common Container Style */
.container {
  max-width: 100%;
}

/* NewsDetailPage Styles */
.heading {
  margin-bottom: 2vw;
  font-weight: 900;
  color: rgb(0, 0, 0);
  font-size: 2vw; 
  font-style: bold;
  padding: 1rem;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  max-height: 70vh; 
  transition: transform 0.3s ease-in-out;
}
.img-fluid:hover {
  transform: scale(1.05);
}

.button-share {
  background-color: #177fef;
  color: white;
  width: 8rem;
  font-weight: bold;
  position: fixed;
  top: 10vh;
  right: 5vw;
  font-size: large;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.button-share:hover {
  background-color: #0a58ca;
}

.no-copy {
  user-select: none;
}
.no-copy img {
  pointer-events: none;
}

.preformatted-text {
  padding: 1rem;
  white-space: pre-wrap;
  word-break: break-word; /* Prevents overflow issues */
  overflow-wrap: break-word;
}

.preformatted-text img {
  max-width: 100%;
  height: auto;
  display: block; /* Ensures centering */
  margin: auto;
}


.embed-responsive-item {
  width: 80%;
  max-width: 100%;
}

.youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 20px auto;
}

@media (min-width: 700px) {
  .youtube-video {
    max-width: 80%;
  }
}

.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media(min-width:700px) {
  .youtube-video {
    padding-bottom: 34%;
    max-width: 60%;
  }
}

.news-blocks {
  flex: 1 1 calc(33.333% - 20px);
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.news-blocks:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 900px) {
  .news-blocks {
    flex: 1 1 calc(40% - 10px);
  }
}

@media (max-width: 700px) {
  .news-blocks {
    flex: 1 1 100%;
  }
}

.news-card {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.card-image {
  width: 100%;
  object-fit: fill;
  height: 35vh;
  transition: transform 0.3s ease;
}

.card-image:hover {
  transform: scale(1.05);
}

.card-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000;
}

.card-date {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.card-location {
  font-size: 0.8rem;
  color: #000;
  margin-bottom: 5px;
}

.read-more {
  color: #177fef;
  text-decoration: none;
  font-weight: bold;
  margin-top: auto;
  align-self: flex-start;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: #0a58ca;
  text-decoration: underline;
}

/* Skeleton Loading Styles */
.skeleton {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.skeleton-image {
  width: 100%;
  height: 35vh;
  background-color: #ddd;
}

.skeleton-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.skeleton-title,
.skeleton-text {
  background-color: #ddd;
  margin-bottom: 10px;
}

.skeleton-title {
  height: 20px;
  width: 80%;
}

.skeleton-text.short {
  width: 60%;
  height: 15px;
}

.skeleton-text.long {
  width: 80%;
  height: 15px;
}

.skeleton-text.small {
  width: 40%;
  height: 15px;
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

body.dark-mode .container {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

body.dark-mode h2, 
body.dark-mode h5, 
body.dark-mode h6, 
body.dark-mode p {
  color: #e0e0e0;
}

body.dark-mode .button-share {
  background-color: #3a3a3a;
  color: #e0e0e0;
}

body.dark-mode .button-share:hover {
  background-color: #5a5a5a;
}

body.dark-mode .news-blocks {
  background-color: #2b2b2b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

body.dark-mode .card-details, 
body.dark-mode .skeleton {
  background-color: #2b2b2b;
  border: 1px solid #444;
}

body.dark-mode .card-title,
body.dark-mode .card-date,
body.dark-mode .card-location {
  color: #e0e0e0;
}

body.dark-mode .read-more {
  color: #9ab1ff;
}

body.dark-mode .read-more:hover {
  text-decoration: underline;
}

body.dark-mode .skeleton-image,
body.dark-mode .skeleton-title,
body.dark-mode .skeleton-text {
  background-color: #3a3a3a;
}

/* Watermark on image */
.image-container {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.watermark-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
  overflow: hidden;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  animation: moveWatermark 10s infinite linear;
}

.watermark-image {
  width: 8em;
  height: auto;
}

@keyframes moveWatermark {
  0% {
    transform: translate(-50%, -50%) translateX(0) translateY(0);
  }
  25% {
    transform: translate(-50%, -50%) translateX(30%) translateY(-30%);
  }
  50% {
    transform: translate(-50%, -50%) translateX(50%) translateY(30%);
  }
  75% {
    transform: translate(-50%, -50%) translateX(-30%) translateY(30%);
  }
  100% {
    transform: translate(-50%, -50%) translateX(0) translateY(0);
  }
}

.col-md-4{
  margin: 2rem 0;
}