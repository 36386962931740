/* src/ContactPage.css */
body {
  font-family: Arial, sans-serif;
}

.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

form {
  max-width: 500px;
  margin: 0 auto;
  padding: 1em;
  /* background: #f9f9f9; */
  border-radius: 5px;
}

.form-group {
  margin-bottom: 1em;
}

.form-group label {
  margin-bottom: .5em;
  /* color: #333333; */
  display: block;
}

.form-group input,
.form-group textarea {
  border: 1px solid #cccccc;
  padding: .5em;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 0.7em;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
}

button:hover {
  background-color: #0056b3;
}
