

@media (max-width: 600px) {
  .context {
    height: 50vh;
  }
  .heading{
    height: 15vh;
  }
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  --w:10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 8vw;
  letter-spacing: var(--w);
  width:var(--w);
  overflow: hidden;
  white-space: nowrap;
  text-shadow: 
    calc(-1*var(--w)) 0, 
    calc(-2*var(--w)) 0, 
    calc(-3*var(--w)) 0, 
    calc(-4*var(--w)) 0,
    calc(-5*var(--w)) 0, 
    calc(-6*var(--w)) 0, 
    calc(-7*var(--w)) 0, 
    calc(-8*var(--w)) 0, 
    calc(-9*var(--w)) 0;
  animation: l16 2s infinite;
}
.loader:before {
  content:"Loading...";
}
@keyframes l16 {
  20% {text-shadow: 
    calc(-1*var(--w)) 0, 
    calc(-2*var(--w)) 0 red, 
    calc(-3*var(--w)) 0, 
    calc(-4*var(--w)) 0 #ffa516,
    calc(-5*var(--w)) 0 #63fff4, 
    calc(-6*var(--w)) 0, 
    calc(-7*var(--w)) 0, 
    calc(-8*var(--w)) 0 green, 
    calc(-9*var(--w)) 0;}
  40% {text-shadow: 
    calc(-1*var(--w)) 0, 
    calc(-2*var(--w)) 0 red, 
    calc(-3*var(--w)) 0 #e945e9, 
    calc(-4*var(--w)) 0,
    calc(-5*var(--w)) 0 green, 
    calc(-6*var(--w)) 0 orange, 
    calc(-7*var(--w)) 0, 
    calc(-8*var(--w)) 0 green, 
    calc(-9*var(--w)) 0;}
  60% {text-shadow: 
    calc(-1*var(--w)) 0 lightblue, 
    calc(-2*var(--w)) 0, 
    calc(-3*var(--w)) 0 #e945e9, 
    calc(-4*var(--w)) 0,
    calc(-5*var(--w)) 0 green, 
    calc(-6*var(--w)) 0, 
    calc(-7*var(--w)) 0 yellow, 
    calc(-8*var(--w)) 0 #ffa516, 
    calc(-9*var(--w)) 0 red;}
  80% {text-shadow: 
    calc(-1*var(--w)) 0 lightblue, 
    calc(-2*var(--w)) 0 yellow, 
    calc(-3*var(--w)) 0 #63fff4, 
    calc(-4*var(--w)) 0 #ffa516,
    calc(-5*var(--w)) 0 red, 
    calc(-6*var(--w)) 0, 
    calc(-7*var(--w)) 0 grey, 
    calc(-8*var(--w)) 0 #63fff4, 
    calc(-9*var(--w)) 0 ;}
}


body.dark-mode  {
  background-color: #212121;
  color: #e0e0e0;
}





/* reporter dashboard */

.container-custom {
  margin-top: 2rem;
}

.form-custom {
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.heading, .context {
  font-size: 1.1rem;
}


.table-custom {
  margin-top: 2rem;
}

.skeleton-row {
  height: 1.5rem;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.modal-dark {
  background-color: #343a40;
  color: #fff;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}



@media (max-width: 768px) {
  .form-custom {
    padding: 1rem;
    height: auto;
  }
  .ql-container{
    height: 70vh;
  }

  .heading, .context {
    font-size: 1rem;
  }
}


.action-column{
  width: 50px;
}



/* Ads Section */

@media(min-width:901px){
  .banner-section {
    margin-top: 50px;
    margin-left: 30%;
    height: 70vh;
    
  }

}
/* Responsive Styles */
@media (min-width: 768px) and (max-width: 900px) {
  .banner-section {
    margin-top: 25px;
    margin-left: 40%;
    height: 65vh;
  }
}
@media (min-width: 601px) and (max-width: 767px) {
  .banner-section {
    margin-top: 10px;
    margin-left: 40%;
    height: 44vh;
  }
}
@media (min-width: 500px) and (max-width: 600px) {
  .banner-section {
    margin-top: 10px;
    margin-left: 40%;
    height: 35vh;
  }
}
@media(max-width:499px){
  .banner-section {
    margin-top: 10px;
    margin-left: 40%;
    height: 30vh;
  }
}
